import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Form, Input, Row, Col, Modal } from "antd";
import RegisterModal from "./register-modal";
import "./styles/style.scss";
import { useAuthloginMutation } from "./services/auth-api";
import { saveTokenState } from "../../utils/local-storage";
import { useNavigate } from "react-router-dom";

export function LoginModal({ handleCancel, status }) {
  const [isRegister, setIsRegister] = useState(false);
  const [authlogin] = useAuthloginMutation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    setIsLoading(true);
    authlogin(values)
      .unwrap()
      .then((res) => {
        saveTokenState(res)
        navigate("/account/profile");
        handleCancel()
      })
      .catch((error) => {
        console.log("error:====>", error);
        setIsLoading(false);
      });
  };


  return (
    <Modal
      className="dialog"
      centered
      visible={status}
      footer={null}
      closable={false}
      onCancel={handleCancel}>

      <RegisterModal status={isRegister} handleCancel={() => setIsRegister(false)} />
      <div className="auth-content">
        <Row>
          <Col lg={24} xs={24}>
            <div className="form-wrapper">
              <h3>Connexion</h3>
              <p>Connectez-vous à votre compte</p>
              <Form
                name="basic"
                initialValues={{ email: "", password: "" }}
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
              >
                <Form.Item
                  label="Numéro de téléphone"
                  name="number"
                  rules={[{ required: true, message: "Champs requis!" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Mot de passe"
                  name="password"
                  rules={[{ required: true, message: "Champs requis!" }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    Connexion
                  </Button>
                </Form.Item>
              </Form>
              <p>
                Vous n'avez pas de compte ?{" "}
                <strong onClick={() => setIsRegister(true)}>
                  {" "}
                  Inscrivez-vous{" "}
                </strong>
              </p>
              <p>
                <NavLink to={"#"}>Mot de passe oublié?</NavLink>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default LoginModal;
