import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-vars";
import { loadTokenState } from "../../../utils/local-storage";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers) => {
      const token = loadTokenState().token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Account"],
  endpoints: (builder) => ({
    accountGetUser: builder.mutation({
      query: (payload) => ({
        url: `/api/getInfoUser/${payload.id}`,
        method: "GET"
      }),
      invalidatesTags: ["Account"],
    }),

    accountADDPost: builder.mutation({
      query: (payload) => ({
        url: `/api/addproduit`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Account"],
    }),

    accountFetchPost: builder.query({
      query: (id) => `/api/listLadoum/${id}`,
      providesTags: ["Account"],
    }),

    accountFetchMale: builder.query({
      query: (id) => `/api/listMale`,
      providesTags: ["Account"],
    }),

    accountFetchFemale: builder.query({
      query: (id) => `/api/listFemale`,
      providesTags: ["Account"],
    }),

  }),
});

export const {
  useAccountGetUserMutation,
  useAccountADDPostMutation,
  useAccountFetchPostQuery,
  useAccountFetchFemaleQuery,
  useAccountFetchMaleQuery
} = accountApi;