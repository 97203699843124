import React, { useEffect, useState, useCallback } from "react";
import { Breadcrumb, Row, Col, Button, Form, Input, Spin } from "antd";
import { useAccountGetUserMutation } from "../services/account-api";
import { loadTokenState } from "../../../utils/local-storage";

const { TextArea } = Input;


export function ProfilePage() {
    const [accountGetUser] = useAccountGetUserMutation();
    const [isLoading, setIsLoading] = useState(false);
    const userId = loadTokenState().data.id
    const [form] = Form.useForm();

    const handleGetUser = useCallback(() => {
        setIsLoading(true);
        accountGetUser({ id: userId })
            .unwrap()
            .then((res) => {
                form.setFieldsValue({
                    nom: res.data.nom,
                    prenom: res.data.prenom,
                    number: res.data.number,
                });
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, [userId, form, accountGetUser]);

    useEffect(() => {
        handleGetUser();
    }, [handleGetUser]);


    return (
        <div className='content shadow animate__animated animate__fadeIn'>
            <div className='breadcrumb'>
                <Breadcrumb>
                    <Breadcrumb.Item>Accueil</Breadcrumb.Item>
                    <Breadcrumb.Item>Compte</Breadcrumb.Item>
                    <Breadcrumb.Item>Profil</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="title">
                <h4>Publier une annonce</h4>
            </div>
            <div className="form-wrapper">
                <Spin tip="Chargement..." spinning={isLoading}>
                    <Form name="basic" layout="vertical" autoComplete="off" form={form} initialValues={{ remember: true }}>
                        <Row gutter={24}>
                            <Col lg={12} xs={24}>
                                <Form.Item label="Nom" name="nom">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item label="Prénom" name="prenom">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col lg={12} xs={24}>
                                <Form.Item label="Téléphone" name="number">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col lg={12} xs={24}>
                                <Form.Item label="email" name="parent_female">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item label="Presentation" name="about">
                            <TextArea rows={4} className="form-control" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary"
                                htmlType="submit"                            >
                                Sauvegarder
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
                <br />
            </div>
        </div>
    );
}

export default ProfilePage;
