import { Breadcrumb } from 'antd';
import { useAccountFetchPostQuery } from '../services/account-api';
import { loadTokenState } from '../../../utils/local-storage';
import { Table, Space, Button } from "antd";


export function PostListPage() {
    const userId = loadTokenState().data.id

    const { data: products } = useAccountFetchPostQuery(userId);

    console.log("products:", products.data);

    const columns = [
        {
            title: "Nom du mouton",
            key: "nom",
            render: (record) => (
                <div className="btn-view">
                    <Space size="middle">
                        <span>{record.nom}</span>
                    </Space>
                </div>
            ),
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (text) => <span>{text}</span>,
        },
        {
            title: "Nom du père",
            dataIndex: "description",
            key: "description",
            render: (text) => <span>{text}</span>,
        },
        {
            title: "Nom de la mère",
            dataIndex: "description",
            key: "description",
            render: (text) => <span>{text}</span>,
        },
        {
            title: "Poids",
            dataIndex: "poid",
            key: "poids",
            render: (text) => <span>{text} kg</span>,
        },
        {
            title: "Taille",
            dataIndex: "taille",
            key: "taille",
            render: (text) => <span>{text}</span>,
        },
        // {
        //     title: "Action",
        //     key: "action",
        //     width: "20%",
        //     render: (record) => (
        //         <Space size="middle">
        //             <div
        //                 className="btn-view"
        //             >
        //                 View
        //             </div>
        //             |
        //             <div

        //                 className="btn-edit"
        //             >
        //                 Edit
        //             </div>
        //             |
        //             <div className="btn-delete">
        //                 Delete
        //             </div>
        //         </Space>
        //     ),
        // },
    ];


    return (
        <div className='content shadow animate__animated animate__fadeIn'>
            <div className='breadcrumb'>
                <Breadcrumb>
                    <Breadcrumb.Item>Accueil</Breadcrumb.Item>
                    <Breadcrumb.Item>Compte</Breadcrumb.Item>
                    <Breadcrumb.Item>Mes annonces</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <Table columns={columns} dataSource={products?.data} />

        </div>
    );
}
export default PostListPage;
