import React, { useState } from "react";
import { message, Upload } from "antd";
import { Breadcrumb, Row, Col, Button, Form, Input, Modal, Result, Select, notification } from "antd";
import { loadTokenState } from "../../../utils/local-storage";
import { useAccountADDPostMutation, useAccountFetchFemaleQuery, useAccountFetchMaleQuery } from "../services/account-api";
const { Option } = Select;

const { TextArea } = Input;
const { Dragger } = Upload;

const props = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",

  onChange(info) {
    const { status } = info.file;

    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }

    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },

  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};


export function PostNewPage() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userId = loadTokenState().data.id
  const [accountADDPost] = useAccountADDPostMutation();
  const [form] = Form.useForm();
  const [error, setError] = useState(false);
  const { data: listMale } = useAccountFetchMaleQuery();
  const { data: listFemale } = useAccountFetchFemaleQuery();


  const onFinish = (values) => {
    const data = { ...values, proprietaire_id: userId }
    setIsLoading(true);
    accountADDPost(data)
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        form.resetFields();
        notification.success({ message: 'Annonce ajoutée avec succès' })
      })
      .catch((error) => {
        console.log("error:====>", error);
        setIsLoading(false);
        setError(error.data?.message || 'Erreur lors de la création');
      });
  };



  return (
    <div className="content shadow animate__animated animate__fadeIn">
      <div className="breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>Accueil</Breadcrumb.Item>
          <Breadcrumb.Item>Compte</Breadcrumb.Item>
          <Breadcrumb.Item>Publier une annonce</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="title">
        <h4>Publier une annonce</h4>
      </div>
      <div className="form-wrapper">
        <Form name="basic" layout="vertical" autoComplete="off" onFinish={onFinish} form={form}>
          <Row gutter={24}>
            <Col lg={12} xs={24}>
              <Form.Item label="Nom" name="nom" rules={[{ required: true, message: "Champs requis!" }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item label="sexe" name="sexe" rules={[{ required: true, message: "Champs requis!" }]}>
                <Select
                  allowClear>
                  <Option value="male">Male</Option>
                  <Option value="female">Femelle</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item label="Parent male" name="pere" rules={[{ required: true, message: "Champs requis!" }]}>
                <Select allowClear>
                  {listMale?.data.map((index, item) =>
                    <Option value={index.id}>{index.nom}</Option>)
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item label="Parent femelle" name="mere" rules={[{ required: true, message: "Champs requis!" }]}>
                <Select allowClear>
                  {listFemale?.data.map((index, item) =>
                    <Option value={index.id}>{index.nom}</Option>)
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item label="Poids" name="poid" rules={[{ required: true, message: "Champs requis!" }]}>
                <Input />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item label="Taille" name="taille" rules={[{ required: true, message: "Champs requis!" }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Description" name="description">
            <TextArea rows={4} className="form-control" />
          </Form.Item>

          {/* <Form.Item label="Image(s)" name="description">
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Ajouter un ou plusieurs images</p>
            </Dragger>
          </Form.Item> */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
            // onClick={() => setIsSuccess(true)}
            >
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
        <br />
        <Modal
          className="auth"
          centered
          visible={isSuccess}
          footer={null}
          closable={false}
        >
          <div className="modal-body">
            <Result
              status="success"
              title="Success!"
              subTitle="L'annonce sera publiée sous réserve de modération
              dans les 24H"
              extra={[
                <Button
                  type="primary"
                  key="console"
                  onClick={() => setIsSuccess(false)}
                >
                  Fermer
                </Button>,
              ]}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}
export default PostNewPage;
